import "App.css";

import { useScrollToTop } from "hooks/use-scroll-to-top";
import Router from "routes/routes";
import Clarity from "@microsoft/clarity";

const projectId = process.env.REACT_APP_CLARITY || "";

export default function App() {
  Clarity.init(projectId);
  useScrollToTop();

  return <Router />;
}
