export interface DropDownProps {
  open: boolean;
  categories: any[];
  index: number;
  link_name: string;
}

export interface NavbarOptionsProps {
  label: string;
  variant: "contained" | "text" | "outlined";
  categories?: any[];
  href?: string;
  type: "modal" | "link" | "node";
  link_name?: "deaf bonce" | "sundown audio" | "dd audio" | "";
}

export interface HeaderDesktopLinksItemProps {
  item: NavbarOptionsProps;
  index: number;
  handleDropDownToggle: (data: NavbarOptionsProps, index: number) => void;
}

export const navbarOptions: NavbarOptionsProps[] = [
  {
    label: "Bulb Finder",
    variant: "contained",
    type: "modal",
  },
  {
    label: "Deaf Bonce",
    variant: "text",
    type: "node",
    link_name: "deaf bonce",
    categories: [
      {
        id: "amplifiers",
        title: "Deafbonce amplifiers",
      },

      {
        id: "speakers",
        title: "Deafbonce speakers",
      },
      {
        id: "subwoofers",
        title: "Deafbonce subwoofers",
      },

      {
        id: "cables",
        title: "Deafbonce cables",
      },

      {
        id: "promos",
        title: "Deafbonce promos",
      },
      {
        id: "accessories",
        title: "Deafbonce accessories",
      },

      {
        id: "recones",
        title: "Deafbonce recones",
      },
    ],
  },

  {
    label: "DD Audio",
    variant: "text",
    type: "node",
    link_name: "dd audio",
    categories: [
      {
        id: "amplifiers",
        title: "DD Audio amplifiers",
      },

      {
        id: "speakers",
        title: "DD Audio speakers",
      },
      {
        id: "subwoofers",
        title: "DD Audio subwoofers",
      },

      {
        id: "cables",
        title: "DD Audio cables",
      },

      {
        id: "promos",
        title: "DD Audio promos",
      },
      {
        id: "accessories",
        title: "DD Audio accessories",
      },

      {
        id: "recones",
        title: "DD Audio recones",
      },
    ],
  },

  {
    label: "Sundown Audio",
    variant: "contained",
    type: "node",
    link_name: "sundown audio",
    categories: [
      {
        id: "amplifiers",
        title: "Sundown Audio amplifiers",
      },

      {
        id: "speakers",
        title: "Sundown Audio speakers",
      },
      {
        id: "subwoofers",
        title: "Sundown Audio subwoofers",
      },

      {
        id: "cables",
        title: "Sundown Audio cables",
      },

      {
        id: "promos",
        title: "Sundown Audio promos",
      },
      {
        id: "accessories",
        title: "Sundown Audio accessories",
      },

      {
        id: "recones",
        title: "Sundown Audio recones",
      },
    ],
  },
  {
    label: "XS Power Batteries",
    variant: "text",
    type: "node",
    categories: [
      {
        id: "agm",
        title: "AGM",
      },
      {
        id: "electronics",
        title: "ELECTRONICS",
      },
      {
        id: "cable_and_kits",
        title: "CABLE AND KITS",
      },

      {
        id: "lithium_lfp",
        title: "LITHIUM (LFP)",
      },
      {
        id: "superbanks",
        title: "SUPERBANKS",
      },

      {
        id: "adaptors",
        title: "ADAPTORS",
      },
      {
        id: "mounts",
        title: "MOUNTS",
      },
    ],
  },
  {
    label: "Nemesis Audio",
    variant: "text",
    type: "node",
    categories: [
      {
        id: "midrange_loudspeaker",
        title: "Midrange Loudspeaker",
      },
      {
        id: "accessories",
        title: "Accessories",
      },
      {
        id: "amplifier",
        title: "Amplifier",
      },
      {
        id: "battery",
        title: "Battery",
      },
      {
        id: "speaker",
        title: "Speaker",
      },
      {
        id: "subwoofer",
        title: "Subwoofer",
      },
      {
        id: "tweeter",
        title: "Tweeter",
      },
    ],
  },
  {
    label: "Contact",
    variant: "text",
    href: "/contact-us",
    type: "link",
  },
];
