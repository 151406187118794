import Button from "@mui/material/Button";
import {
  MuiLoadingButton,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Formik,
  Form,
  Box,
  CustomIconButton,
  CloseRounded,
} from "components/common";
import * as React from "react";
import { Fragment } from "react";

import {
  EmailAddress,
  FirstName,
  LastName,
  Password,
  PhoneNumber,
} from "./AuthInputs";
import useAuthFunctions from "./ts/useAuthFunctions";
import useFunctions from "layouts/main-layout/ts/useFunctions";

export default function SignupForm() {
  const {
    initialSignupValues,
    validationSignupSchema,
    setTab,
    onRegisterSubmit,
  } = useAuthFunctions();
  const { handleLoginDialogToggle } = useFunctions();

  return (
    <Fragment>
      <DialogContent>
        <Box display="flex" alignItems="center" gap={2} mb={2}>
          <Box display={"flex"} alignItems="center" gap={3}>
            <CustomIconButton
              color="primary"
              onClick={() => handleLoginDialogToggle()}
            >
              <CloseRounded />
            </CustomIconButton>
            <DialogTitle
              sx={{
                color: "#666",
                fontSize: "36px",
              }}
            >
              Create Account
            </DialogTitle>
          </Box>
        </Box>
        {/* <DialogTitle
          sx={{
            color: "#666",
            padding: "0px 0px",
            fontSize: "36px",
          }}
        >
          
        </DialogTitle> */}
        <Formik
          initialValues={initialSignupValues}
          validationSchema={validationSignupSchema}
          onSubmit={onRegisterSubmit}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <DialogContentText>
                Please Register using account below.
              </DialogContentText>
              <FirstName />
              <LastName />
              <EmailAddress />
              <PhoneNumber />
              <Password />
              <DialogActions
                sx={{
                  flexDirection: "column",
                }}
              >
                <MuiLoadingButton
                  type="submit"
                  loading={isSubmitting}
                  sx={{
                    width: "100%",
                    background: "#00E852",
                    color: "#fff",
                    marginBottom: "10px",
                  }}
                >
                  Create Account
                </MuiLoadingButton>
                <DialogContentText>
                  Already have an account?{" "}
                  <MuiLoadingButton
                    variant="text"
                    color="primary"
                    onClick={() => setTab(0)}
                  >
                    <span
                      style={{
                        color: "#00E852",
                        cursor: "pointer",
                      }}
                    >
                      Log in
                    </span>
                  </MuiLoadingButton>
                </DialogContentText>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Fragment>
  );
}
