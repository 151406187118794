import { ListItemButton, MenuItem, MenuList, Typography } from "@mui/material";
import {
  CustomIconButton,
  Logout,
  MuiAvatar,
  MuiDivider,
  MuiListItemIcon,
  MuiMenu,
  MuiMenuItem,
  PersonAdd,
  Settings,
} from "components/common";
import useFunctions from "layouts/authed-layout/ts/useFunctions";
import { Fragment, MouseEvent, useState } from "react";
import { theme } from "theme";

export default function HeaderAccountMenu() {
  const { user, handleAuthReset } = useFunctions();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Fragment>
      <CustomIconButton
        color="secondary"
        sx={{
          padding: "0 !important",
          display: { xs: "none", md: "flex" },
        }}
        onClick={handleClick}
      >
        <MuiAvatar
          sx={{
            width: "46px",
            height: "46px",
          }}
        >
          {user.first_name.charAt(0) + user.last_name.charAt(0)}
        </MuiAvatar>
      </CustomIconButton>
      <MuiMenu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{
          sx: {
            p: 1,
            color: "black",
          },
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              bgcolor: "background.paper",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {[
          {
            title: "Profile",
          },
          {
            title: "Settings",
          },
          {
            title: "Logout",
            onClick: handleAuthReset,
          },
        ].map(({ title, onClick }, index) => (
          <Fragment>
            <MenuItem
              sx={{
                borderRadius: "10px",
                "& .MuiTypography-root": {
                  color: "black",
                },
                "&:hover": {
                  backgroundColor: "primary.main",
                  "& .MuiTypography-root": {
                    color: "white",
                  },
                },
              }}
              onClick={onClick && onClick}
            >
              <Typography>{title}</Typography>
            </MenuItem>
            {index == 0 && <MuiDivider sx={{ my: 1 }} />}
          </Fragment>
        ))}
      </MuiMenu>
    </Fragment>
  );
}
