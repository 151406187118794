import {
  Box,
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Skeleton,
} from "@mui/material";
import NavItem from "./NavItem";
import {
  ArrowUpwardRounded,
  KeyboardArrowUpRounded,
  HomeRounded as MenuItem0,
  TungstenRounded as MenuItem1,
  ContactMailRounded as MenuItem6,
  LogoutRounded as MenuItem8,
} from "@mui/icons-material";
import { useState } from "react";
import BulbFinderModal from "components/bulb-finder-modal";
import useFunctions from "layouts/authed-layout/ts/useFunctions";
import { useResetAuthDataState } from "states/authState";
import { MuiAvatar } from "components/common";

const icons = {
  MenuItem0,
  MenuItem1,
  MenuItem6,
  MenuItem8,
};

const Navigation = () => {
  const {
    user,
    dealerSwitch,
    handleDealerSwitchToggle,
    handleBecomeDealer,
    handleAuthReset,
    userApiLoader,
  } = useFunctions();

  const [bulbFinderOpen, setBulbFinderOpen] = useState(false);
  const [profileMenu, setProfileMenu] = useState(false);
  const handleProfileMenuToggle = () => setProfileMenu(!profileMenu);

  const handleBulbFinderOpen = () => setBulbFinderOpen(true);
  const handleBulbFinderClose = () => setBulbFinderOpen(false);

  const items = [
    {
      id: 0,
      title: "Home",
      url: "/",
      icon: icons.MenuItem0,
      type: "button",
    },
    {
      id: 1,
      title: "Bulb Finder",
      url: "#",
      icon: icons.MenuItem1,
      type: "button",
      onClick: () => handleBulbFinderOpen(),
    },
    {
      id: 3,
      title: "Contact",
      url: "/contact-us",
      icon: icons.MenuItem6,
      type: "button",
    },
  ];

  const navLinks = items.map((item) => (
    <NavItem key={item.id} item={item} onClick={handleBulbFinderOpen} />
  ));

  return (
    <Box sx={{ px: 2 }}>
      {navLinks}
      <Box
        sx={{
          position: "absolute",
          bottom: 12,
          width: "calc(100% - 32px)",
        }}
      >
        <Collapse in={profileMenu}>
          <Paper>
            <MenuList sx={{ py: 1 }}>
              {[
                {
                  title: "Profile",
                },
                {
                  title:
                    user.isDealer && user?.dealerDetails.progress === "approved"
                      ? dealerSwitch
                        ? "Switch to retail"
                        : "Switch to dealer"
                      : "Become a Dealer",
                  onClick:
                    user.isDealer && user?.dealerDetails.progress === "approved"
                      ? handleDealerSwitchToggle
                      : handleBecomeDealer,
                },
                {
                  title: "Logout",
                  onClick: handleAuthReset,
                },
              ].map((item, index) => (
                <ListItemButton
                  onClick={item.onClick && item.onClick}
                  sx={{
                    mx: 1,
                    "&:hover": {
                      "& svg": {
                        fill: "#fff",
                      },
                      "& span": {
                        color: "#fff",
                      },
                    },
                  }}
                >
                  <ListItemText
                    primary={userApiLoader ? <Skeleton /> : item.title}
                  />
                </ListItemButton>
              ))}
            </MenuList>
          </Paper>
        </Collapse>
        <ListItemButton
          selected={profileMenu}
          onClick={handleProfileMenuToggle}
          sx={{
            my: 1,
            pl: 2,
            py: 1.5,
            gap: 1,
            "&:hover": {
              "& svg": {
                fill: "#fff",
              },
              "& span": {
                color: "#fff",
              },
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 28,
            }}
          >
            {userApiLoader ? (
              <Skeleton width="30px" height="30px" variant="circular" />
            ) : (
              <MuiAvatar
                sx={{
                  width: "30px",
                  height: "30px",
                  fontSize: "14px",
                }}
              >
                {user.first_name.charAt(0) + user.last_name.charAt(0)}
              </MuiAvatar>
            )}
          </ListItemIcon>
          <ListItemText
            sx={{
              fontSize: "17px",
            }}
            primary={
              userApiLoader ? (
                <Skeleton width="60%" />
              ) : (
                user.first_name + " " + user.last_name
              )
            }
          />
          <ListItemIcon
            sx={{
              minWidth: 28,
            }}
          >
            <KeyboardArrowUpRounded />
          </ListItemIcon>
        </ListItemButton>
      </Box>
      <BulbFinderModal open={bulbFinderOpen} onClose={handleBulbFinderClose} />{" "}
    </Box>
  );
};

export default Navigation;
