import { GridProps } from "@mui/material";
import { Grid, styled } from "components/common";
import LoaderImage from "assets/gif/bg-card-loader.gif";

interface CustomProps extends GridProps {
  imgLoading: boolean;
}

export const ProductCardWrapper = styled(Grid)<CustomProps>(
  ({ theme, imgLoading }) => ({
    userSelect: "none",
    "& .main": {
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "calc(100% - 40px)",
    },
    "& .img-container": {
      border: "2px solid #E5E5E5",
      padding: "20px",
      borderRadius: "4px",
      backgroundImage: imgLoading ? `url(${LoaderImage})` : "none",
      backgroundSize: "30%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      "& img": {
        width: "100%",
        aspectRatio: "1/1",
      },
    },
  })
);
