import React from "react";
import { Box, Grid, Typography } from "components/common";
import { Link } from "react-router-dom";

const HeaderDesktopLinksAccordionComponent = ({
  dropDown,
  setDropDown,
}: any) => {
  const { label, categories, link_name } = dropDown;
  console.log(dropDown);
  return (
    <Box>
      <Box sx={{ padding: "10px 15px" }}>
        <Typography sx={{ color: "#000", fontSize: "20px", fontWeight: "500" }}>
          {label?.toUpperCase()}
        </Typography>
      </Box>
      <Box sx={{ padding: 4, maxHeight: 500, overflowY: "auto" }}>
        <Grid
          container
          spacing={4}
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr",
              md: "1fr 1fr",
              lg: "1fr 1fr 1fr",
            },
            gap: 3,
          }}
        >
          {categories.map((category: any) => (
            <Box key={category.title}>
              <Box
                to={`/products?brand=${link_name}&category=${category.id}`}
                onClick={setDropDown}
                component={(props: any) => (
                  <Link {...props}>{props.children}</Link>
                )}
                sx={{
                  marginBottom: 1,
                  padding: "8px 16px",
                  cursor: "pointer",
                  transition: "all 0.3s ease",
                  fontWeight: "500",
                  color: "#333",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#00E852",
                    color: "#fff",
                    padding: "8px 20px",
                  },
                }}
              >
                {category.title}
              </Box>
            </Box>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default HeaderDesktopLinksAccordionComponent;
