import {
  Autocomplete,
  MenuItem,
  Paper,
  TextField,
  useMediaQuery,
  Box,
  MenuList,
  ListItemButton,
} from "@mui/material";
import { CustomIconButton, SearchRounded } from "components/common";
import { useState, useMemo, useEffect } from "react";
import debounce from "lodash.debounce";
import { theme } from "theme";
import { searchClient } from "utils/searchClient";
import { Link, useNavigate } from "react-router-dom";

interface AlgoliaHit {
  objectID: string;
  title: string;
}

export default function HeaderSearch() {
  const navigate = useNavigate();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("md"));
  const [loading, setLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<AlgoliaHit[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  const fetchOptions = async (query: string) => {
    if (query.length === 0) {
      setOptions([]);
      setOpen(false);
      return;
    }
    setLoading(true);

    try {
      const response = await searchClient.search([
        {
          indexName: "products",
          query,
          params: {
            hitsPerPage: 5,
          },
        },
      ]);

      //@ts-ignore
      const hits = response.results[0]?.hits || [];
      console.log("Algolia hits:", hits);
      setOptions(hits);
      setOpen(hits.length > 0);
    } catch (error) {
      console.error("Error fetching Algolia results:", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchOptions = useMemo(() => debounce(fetchOptions, 1000), []);

  const handleInputChange = (query: string) => {
    setInputValue(query);
    debouncedFetchOptions(query);
  };

  useEffect(() => {
    return () => {
      debouncedFetchOptions.cancel();
    };
  }, [debouncedFetchOptions]);

  const handleOptionChange = (
    event: React.SyntheticEvent,
    value: string | AlgoliaHit | null,
    reason: string
  ) => {
    // Ensure value is of type AlgoliaHit
    if (value && typeof value !== "string" && value.objectID) {
      navigate(`/product-details/${value.objectID}`);
    }
  };

  return (
    <Autocomplete
      freeSolo
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={options}
      loading={loading}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.title
      }
      filterOptions={(options) => options}
      sx={{ width: { xs: "100%", md: 400 }, maxWidth: 400 }}
      onInputChange={(_, value) => handleInputChange(value)}
      noOptionsText="No Product Found"
      onChange={handleOptionChange}
      PaperComponent={(params) => (
        <Paper {...params}>
          {options.length === 0 && !loading && inputValue.length > 0 && (
            <Box sx={{ padding: 2, textAlign: "center", color: "gray" }}>
              No Product Found
            </Box>
          )}
          {params.children}
        </Paper>
      )}
      ListboxComponent={(params) => (
        <MenuList {...params} sx={{ py: 1 }}>
          {params.children}
        </MenuList>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search Products"
          margin="none"
          variant="outlined"
        />
      )}
      renderOption={(props, option) => (
        <MenuItem
          {...props}
          color="primary"
          // onClick={() => navigate(`/product-details/${option.objectID}`)}
          sx={{
            mx: 1,
            "&:hover": {
              "& svg": {
                fill: "#fff",
              },
              "& span": {
                color: "#fff",
              },
            },
          }}
        >
          {option.title}
        </MenuItem>
      )}
    />
  );
}
