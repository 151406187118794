import { Box } from "@mui/material";
import NavItem from "./NavItem";
import {
  HomeRounded as MenuItem0,
  TungstenRounded as MenuItem1,
  ContactMailRounded as MenuItem6,
  LoginRounded as MenuItem8,
} from "@mui/icons-material";
import { useState } from "react";
import BulbFinderModal from "components/bulb-finder-modal";
import useFunctions from "layouts/main-layout/ts/useFunctions";

const icons = {
  MenuItem0,
  MenuItem1,
  MenuItem6,
  MenuItem8,
};

const items = [
  {
    id: 0,
    title: "Home",
    url: "/",
    icon: icons.MenuItem0,
    type: "link",
  },
  {
    id: 1,
    title: "Bulb Finder",
    url: "#",
    icon: icons.MenuItem1,
    type: "button",
  },
  {
    id: 3,
    title: "Contact",
    url: "/contact-us",
    icon: icons.MenuItem6,
    type: "link",
  },
];

const Navigation = () => {
  const { handleLoginDialogToggle } = useFunctions();
  const [bulbFinderOpen, setBulbFinderOpen] = useState(false); // State for the modal

  const handleBulbFinderOpen = () => setBulbFinderOpen(true);
  const handleBulbFinderClose = () => setBulbFinderOpen(false);

  const navLinks = items.map((item) => (
    <NavItem key={item.id} item={item} onClick={handleBulbFinderOpen} />
  ));

  return (
    <Box sx={{ px: 2 }}>
      {navLinks}
      <Box
        sx={{
          position: "absolute",
          bottom: 12,
          width: "calc(100% - 32px)",
        }}
      >
        <NavItem
          item={{
            title: "Login",
            icon: icons.MenuItem8,
            type: "button",
          }}
          onClick={handleLoginDialogToggle}
        />
      </Box>
      <BulbFinderModal open={bulbFinderOpen} onClose={handleBulbFinderClose} />{" "}
    </Box>
  );
};

export default Navigation;
